import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import CancelledContent from "../components/cancelledContent";
import i18nMessages from '../i18n/en.json';
import 'intl/locale-data/jsonp/en';
import PageWrapper from "../components/pageWrapper";

const Cancelled = (props) => {
    
    return (
        <PageWrapper location={props.location} lang="en" i18nMessages={i18nMessages}>
            <CancelledContent location={props.location} />
        </PageWrapper>
    )
}

export default Cancelled

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`